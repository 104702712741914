/* eslint-disable react/prop-types */
import React from 'react'
import SweetModal from './SweetModal'
import * as LogHelper from '../helper/LogHelper'

class LoginModal extends React.PureComponent {
  onGoogleLoginClick = () => {
    LogHelper.login('Google')
  }

  onFacebookLoginClick = () => {
    LogHelper.login('Facebook')
  }

  onAppleLoginClick = () => {
    LogHelper.login('Apple')
  }

  render() {
    return (
      <SweetModal
        title='Login'
        visible={this.props.isOpened}
        onCancel={this.props.onCancel}
        isCompact>
        <h1 style={{ textAlign: 'center', marginBottom: 12 }}>Welcome!</h1>
        <p style={{ textAlign: 'center' }}>Sign into your account here.</p>
        <div className='centered'>
          <a onClick={this.onGoogleLoginClick} href={(this.props.redirect ? `/login/google?redirect=${this.props.redirect}` : '/login/google')}>
            <div className='header-sign-in-google'>
              <div>
                <svg width="25" height="25"><g fill="none" fillRule="evenodd"><path d="M20.66 12.7c0-.61-.05-1.19-.15-1.74H12.5v3.28h4.58a3.91 3.91 0 0 1-1.7 2.57v2.13h2.74a8.27 8.27 0 0 0 2.54-6.24z" fill="#4285F4"></path><path d="M12.5 21a8.1 8.1 0 0 0 5.63-2.06l-2.75-2.13a5.1 5.1 0 0 1-2.88.8 5.06 5.06 0 0 1-4.76-3.5H4.9v2.2A8.5 8.5 0 0 0 12.5 21z" fill="#34A853"></path><path d="M7.74 14.12a5.11 5.11 0 0 1 0-3.23v-2.2H4.9A8.49 8.49 0 0 0 4 12.5c0 1.37.33 2.67.9 3.82l2.84-2.2z" fill="#FBBC05"></path><path d="M12.5 7.38a4.6 4.6 0 0 1 3.25 1.27l2.44-2.44A8.17 8.17 0 0 0 12.5 4a8.5 8.5 0 0 0-7.6 4.68l2.84 2.2a5.06 5.06 0 0 1 4.76-3.5z" fill="#EA4335"></path></g></svg>
              </div>
              <span>Sign in with Google</span>
            </div>
          </a>
        </div>
        <div className='centered'>
          <a onClick={this.onFacebookLoginClick} href={(this.props.redirect ? `/login/facebook?redirect=${this.props.redirect}` : '/login/facebook')}>
            <div className='header-sign-in-facebook'>
              <div>
                <svg width="25" height="25" fill="#FFFFFF"><path d="M20.3 4H4.7a.7.7 0 0 0-.7.7v15.6c0 .38.32.7.7.7h8.33v-6.38h-2.12v-2.65h2.12V9.84c0-2.2 1.4-3.27 3.35-3.27.94 0 1.75.07 1.98.1v2.3H17c-1.06 0-1.31.5-1.31 1.24v1.76h2.65l-.53 2.65H15.7l.04 6.38h4.56a.7.7 0 0 0 .71-.7V4.7a.7.7 0 0 0-.7-.7" fillRule="evenodd"></path></svg>
              </div>
              <span>Sign in with Facebook</span>
            </div>
          </a>
        </div>
        <div className='centered'>
          <a onClick={this.onAppleLoginClick} href={(this.props.redirect ? `/login/apple?redirect=${this.props.redirect}` : '/login/apple')}>
            <div className='header-sign-in-apple'>
              <div>
                <svg width="25" height="25" fill="#FFFFFF"><g><path id="path4" d="M20.7,16.4c-0.3,0.7-0.6,1.3-1,1.9c-0.5,0.8-1,1.3-1.3,1.6
      c-0.5,0.5-1.1,0.7-1.7,0.8c-0.4,0-1-0.1-1.6-0.4c-0.6-0.3-1.2-0.4-1.7-0.4c-0.5,0-1.1,0.1-1.7,0.4c-0.6,0.3-1.1,0.4-1.5,0.4
      c-0.6,0-1.2-0.2-1.7-0.8c-0.4-0.3-0.8-0.9-1.4-1.7c-0.6-0.8-1.1-1.8-1.5-2.9C5,14,4.8,12.9,4.8,11.7c0-1.3,0.3-2.4,0.8-3.3
      c0.4-0.7,1-1.3,1.7-1.8C8.1,6.2,8.9,6,9.8,6c0.5,0,1.1,0.1,1.8,0.4c0.8,0.3,1.2,0.4,1.5,0.4c0.2,0,0.7-0.2,1.6-0.5
      c0.9-0.3,1.6-0.4,2.2-0.4c1.6,0.1,2.8,0.8,3.6,1.9c-1.4,0.9-2.2,2.1-2.1,3.7c0,1.2,0.5,2.2,1.3,3.1c0.4,0.4,0.8,0.7,1.3,0.9
      C20.9,15.8,20.8,16.1,20.7,16.4L20.7,16.4z M17,1.8c0,1-0.4,1.9-1.1,2.7c-0.8,1-1.9,1.6-3,1.5c0-0.1,0-0.2,0-0.4
      c0-0.9,0.4-1.9,1.1-2.7c0.4-0.4,0.8-0.7,1.4-1c0.5-0.3,1.1-0.4,1.6-0.4C17,1.5,17,1.6,17,1.8L17,1.8z" /></g></svg>
              </div>
              <span>Sign in with Apple</span>
            </div>
          </a>
        </div>

      </SweetModal >
    )
  }
}

export default LoginModal