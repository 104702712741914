/* eslint-disable react/prop-types */
import React from 'react'
import { Dropdown, Menu } from 'antd'
import LoginModal from './LoginModal'

class Header extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }

  state = {
    isLoginModalOpened: false,
    isMobileMenuOpened: false,
  }

  resize = () => {
    let isNotSmallScreen = window.innerWidth >= 700
    if (this.state.isMobileMenuOpened && isNotSmallScreen) {
      this.setState({ isMobileMenuOpened: false })
    }
  }

  handleMobileMenu = () => {
    const menuOpened = !this.state.isMobileMenuOpened
    this.setState({ isMobileMenuOpened: menuOpened })
    if (menuOpened) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }

  profileDropdownMenu = (
    <Menu>
      <Menu.Item>
        <a href='/profile/library'>Legacy Library</a>
      </Menu.Item>
      <Menu.Item>
        <a href='/profile/settings'>Legacy Settings</a>
      </Menu.Item>
      <Menu.Item>
        <a href='/logout'>Logout</a>
      </Menu.Item>
    </Menu>
  )

  onOpenLoginModal = () => {
    this.setState({ isLoginModalOpened: true })
  }

  onCloseLoginModal = () => {
    this.setState({ isLoginModalOpened: false })
  }

  render() {
    const toggleClass = 'header-toggle' + (this.state.isMobileMenuOpened ? ' icon-times' : ' icon-bars')
    const menuItemsClass = 'header-menu-items' + (this.state.isMobileMenuOpened ? ' selected' : '')
    return (
      <React.Fragment>
        <header style={{ marginTop: this.props.isStickyTopBarVisible ? 76 : 0 }}>
          <div className='header-logo'>
            <a className='header-legacy' href='/'>ChordChord</a>
          </div>
          <i className={toggleClass} onClick={this.handleMobileMenu}></i>
          <div className={menuItemsClass}>
            <MenuItem href='https://chordchord.com/generator?utm_campaign=legacy&utm_medium=header_url&utm_source=legacy.chordchord.com' name='Generator' isSpecial />
            {/* <MenuItem href='/samples/midi-pack' name='MIDI Pack' /> */}
            <MenuItem href='https://chordchord.com/blog' name='Blog' />
            {!this.props.isUserLoggedIn ?
              (
                <React.Fragment>
                  <button className='header-sign-in-button' onClick={this.onOpenLoginModal}>Sign In</button>
                </React.Fragment>
              )
              : (
                <Dropdown
                  overlay={this.profileDropdownMenu}>
                  <button className='header-sign-in-button'>Profile</button>
                </Dropdown>
              )
            }
          </div>
        </header>
        <LoginModal onCancel={this.onCloseLoginModal} isOpened={this.state.isLoginModalOpened} redirect={this.props.redirect} />
      </React.Fragment>
    )
  }
}

function MenuItem(props) {
  const className = 'header-menu-item' + (props.isSelected ? ' selected' : '') + (props.isSpecial ? ' special' : '')
  return <div className={className}>
    <a href={props.href}>{props.name}</a>
  </div>
}

export default Header
