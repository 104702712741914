/* eslint-disable react/prop-types */
import React from 'react'

class Footer extends React.PureComponent {
  render() {
    return (
      <footer>
        <div className='footer-columns'>
          <div className='footer-primary-column'>
            <div className='footer-logo'>ChordChord</div>
            <div className='footer-description'>This is a legacy version of ChordChord. Check out a brand-new <a href='https://chordchord.com?utm_campaign=legacy&utm_medium=footer_text&utm_source=legacy.chordchord.com'>ChordChord.com</a></div>
          </div>

          <div className='footer-column'>
            <MenuItem href='https://chordchord.com/generator?utm_campaign=legacy&utm_medium=footer_url&utm_source=legacy.chordchord.com' name='New Generator' />
            <MenuItem href='https://chordchord.com/blog' name='Blog' />
            <MenuItem href='https://chordchord.com/terms-of-service' name='Terms of service' />
            <MenuItem href='https://chordchord.com/privacy-policy' name='Privacy policy' />
          </div>

          <div className='footer-column'>
            <div className='footer-menu-label'>Follow us</div>
            <a className='footer-icon' href='https://instagram.com/chordchord.app' target='_blank' rel='noopener noreferrer'><i className='icon-instagram' /></a>
            <a className='footer-icon' href='https://facebook.com/chordchord.official' target='_blank' rel='noopener noreferrer'><i className='icon-facebook' /></a>
          </div>
        </div>
        <div className='footer-copyright'>©{new Date().getFullYear()} ChordChord LTD. All rights reserved.</div>
      </footer>
    )
  }
}

function MenuItem(props) {
  if (props.withoutHref)
    return <div className='footer-menu-item'>{props.name}</div>
  return <div className='footer-menu-item'>
    <a href={props.href}>{props.name}</a>
  </div>
}

export default Footer
