/* eslint-disable react/prop-types */
import React from 'react'
import { CSSTransition } from 'react-transition-group'

const CLASS_MODAL_OPEN = 'sweet-modal-open'

class SweetModal extends React.PureComponent {
  static defaultProps = {
    className: '',
    title: '',
    withPadding: true,
    isLight: false,
    isCompact: false,
  }

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.isStackedModal = false
  }

  open() {
    this.isStackedModal = document.body.classList.contains(CLASS_MODAL_OPEN)
    document.body.classList.add(CLASS_MODAL_OPEN)
    this.myRef.current && this.myRef.current.focus()
    document.addEventListener('keydown', this.onKeyDown, false)
  }

  close() {
    !this.isStackedModal && document.body.classList.remove(CLASS_MODAL_OPEN)
    this.isStackedModal = false
    document.removeEventListener('keydown', this.onKeyDown, false)
  }

  onKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.props.onCancel()
    }
  }

  componentDidMount() {
    if (this.props.visible) {
      this.open()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.open()
    } else if (!this.props.visible && prevProps.visible) {
      this.close()
    }
  }

  render() {
    const classes = ['sweet-modal-wrap']
    this.props.highmodal && classes.push('high-modal')
    const classesString = classes.join(' ')

    const maskClasses = ['sweet-modal-mask']
    this.props.isLight && maskClasses.push('light')
    const maskClassesString = maskClasses.join(' ')

    const modalClasses = ['sweet-modal']
    this.props.isCompact && modalClasses.push('compact')
    const modalClassesString = modalClasses.join(' ')

    const contentClassesString = ['sweet-modal-content', this.props.className].join(' ')

    const bodyClasses = ['sweet-modal-body']
    this.props.withPadding && bodyClasses.push('with-padding')
    const bodyClassesString = bodyClasses.join(' ')

    return (
      <CSSTransition
        in={this.props.visible}
        timeout={250}
        classNames='sweet-modal-wrap'
        unmountOnExit>
        <div tabIndex='0' ref={this.myRef} className={classesString} role='dialog'>
          <div className={maskClassesString}
            onClick={() => this.props.onCancel()}></div>
          <div className={modalClassesString} role='document'>
            <div className={contentClassesString}>
              <button className='sweet-modal-close' type='button' aria-label='Close' onClick={() => this.props.onCancel()}><span><i aria-label='icon: close'>
                <svg viewBox='64 64 896 896' focusable='false' data-icon='close' width='1em' height='1em' fill='currentColor' aria-hidden='true'>
                  <path d='M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z'></path></svg></i></span>
              </button>
              {this.props.title &&
                <div className='sweet-modal-header'>
                  <div className='sweet-modal-title'>
                    {this.props.title}
                  </div>
                </div>
              }
              <div className={bodyClassesString}>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    )

  }
}

export default SweetModal