/* eslint-disable quotes */
import * as Sentry from '@sentry/browser'
// import { hotjar } from 'react-hotjar'
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import ReactPixel from 'react-facebook-pixel'

const SUBSCRIPTIONS = {
  574856: 'Monthly Premium',
  574858: 'Annual Premium',
  578945: 'Annual Christmas Subscription',
}

const BUILD_VERSION = process.env.BUILD_VERSION

const SENTRY_DSN = 'https://0fa0d146f208401ca5026246b889f2fe@sentry.io/2156169'
// const HOTJAR_ID = 1033907
// const HOTJAR_CODE_VERSION = 2
const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-90862428-15'
const FACEBOOK_PIXEL_ID = '634122200384958'

let GoogleAnalytics = null

export function init() {
  Sentry.init({
    dsn: SENTRY_DSN,
    ignoreErrors: [
      'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
      `Failed to execute 'connect' on 'AudioNode': No function was found that matched the signature provided.`,
    ],
    environment: process.env.SENTRY_ENV,
    release: BUILD_VERSION,
  })

  GoogleAnalytics = Analytics({
    app: 'ChordChord',
    version: BUILD_VERSION,
    plugins: [
      googleAnalytics({
        trackingId: GOOGLE_ANALYTICS_TRACKING_ID,
      }),
    ],
  })
  GoogleAnalytics.page()

  ReactPixel.init(FACEBOOK_PIXEL_ID)
  ReactPixel.pageView()

  // hotjar.initialize(HOTJAR_ID, HOTJAR_CODE_VERSION)

  console.log(BUILD_VERSION)
}

export function logCategory(category, msg, data = {}) {
  Sentry.addBreadcrumb({
    category: category,
    message: msg,
    data: data,
    level: Sentry.Severity.Info,
  })
  GoogleAnalytics.track(category, {
    label: msg,
  })
}

export function logSentry(msg, data = {}) {
  Sentry.addBreadcrumb({
    category: 'log()',
    message: msg,
    data: data,
    level: Sentry.Severity.Info,
  })
}

export function err(err) {
  return Sentry.captureException(err)
}

export function logAnalytics(eventName, params = {}) {
  ReactPixel.track(eventName, params)

  GoogleAnalytics.track(eventName, params)

  try {
    // eslint-disable-next-line no-undef
    FB.AppEvents.logEvent(eventName, 0, params)
  } catch (err) {
    console.log(err)
  }

}

export function adLoaded(item, img) {
  logAnalytics('ad-loaded', { item, img })
}

export function adClicked(item, img) {
  logAnalytics('ad-clicked', { item, img })
}

export function showLoginDialog() {
  logAnalytics('ShowLoginDialog')
}

export function showLimitedDialog() {
  logAnalytics('ShowLimitedDialog')
}

export function login(platform) {
  logAnalytics('Login', { platform })
}

export function viewPricing() {
  logAnalytics('ViewPricing')
}

export function initiateCheckout(userId, subscriptionId) {
  // Log the event for Pixel
  ReactPixel.track('InitiateCheckout')

  // Log the event for the Fadebook app (Javascript SDK)
  // eslint-disable-next-line no-undef
  FB.AppEvents.setUserID(userId)
  GoogleAnalytics.identify(userId)

  let parameters = {}

  Sentry.addBreadcrumb({
    category: 'log()',
    message: 'FB.AppEvents.ParameterNames.CONTENT_ID',
    // eslint-disable-next-line no-undef
    data: FB.AppEvents.ParameterNames.CONTENT_ID,
    level: Sentry.Severity.Info,
  })

  // eslint-disable-next-line no-undef
  parameters[FB.AppEvents.ParameterNames.CONTENT_ID] = SUBSCRIPTIONS[subscriptionId]

  Sentry.addBreadcrumb({
    category: 'log()',
    message: 'FB.AppEvents.EventNames',
    // eslint-disable-next-line no-undef
    data: FB.AppEvents.EventNames,
    level: Sentry.Severity.Info,
  })
  // eslint-disable-next-line no-undef
  FB.AppEvents.logEvent(FB.AppEvents.EventNames.INITIATED_CHECKOUT, 0, parameters)
  GoogleAnalytics.track('InitiateCheckout')
}

export function startTrial(userId, paddleData) {
  // Log the event for Pixel
  ReactPixel.track('StartTrial')

  // Log the event for the Fadebook app (Javascript SDK)
  // eslint-disable-next-line no-undef
  FB.AppEvents.setUserID(userId)
  GoogleAnalytics.identify(userId)

  let parameters = {}

  Sentry.addBreadcrumb({
    category: 'log()',
    message: 'FB.AppEvents.ParameterNames.CONTENT_ID',
    // eslint-disable-next-line no-undef
    data: FB.AppEvents.ParameterNames.CONTENT_ID,
    level: Sentry.Severity.Info,
  })

  // eslint-disable-next-line no-undef
  parameters[FB.AppEvents.ParameterNames.CONTENT_ID] = paddleData.eventData.product.name
  // eslint-disable-next-line no-undef
  FB.AppEvents.logEvent('StartTrial', 0, parameters)
  GoogleAnalytics.track('StartTrial')
}